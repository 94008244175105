<template>
  <!-- MODAL Link Machine -->
    <div id="textAlertModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
      <div class="col-12 m-0 p-0 section-container rounded-5 bg-caja sombra-modal">
        <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
          <div class="col-10">
            <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
              <ion-icon :name="(linkAction==='link')?'log-in-outline':'log-out-outline'" class="tx-18 me-2 align-bottom"></ion-icon>
                {{(linkAction==='link') ?dictionary['Vincular'][session.user.language]:dictionary['Desvincular'][session.user.language]}}
            </h6>
          </div>
          <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="this.$emit('dismissAlert')">	
            <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
          </div>
        </div>
  
        <div class="form-group col-12 p-0 pe-3">
          <div id="linkMachineScroll" class="col-12 p-3" style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
            <div class="row col-12 border border rounded m-0 p-1 pb-0">
              <div class="col-lg-4 col-12">
                <div class="col-12 m-0 p-2 d-flex flex-column tx-left">
                  <span class="col-12 m-0 p-0 tx-bold tx-14">
                    {{(dictionary[(this.$route.params.currentDisplay==='machines')?"Máquina":"Centro de mando"][session.user.language])+": "}}
                  </span>
                  <span class="col-12 m-0 p-0 tx-12">
                    {{targetMachineId}}
                  </span>
                </div>
              
                <div v-if="linkAction === 'link'" 
                class="col-12 m-0 p-2 d-flex flex-column tx-left">
                  <span class="col-12 m-0 p-0 tx-bold tx-14">
                    {{dictionary['Tipo'][session.user.language]+": "}}
                  </span>
                  <select class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex" style="color:black; line-height:"
                  v-model="parentType"
                  @change="onChangeParentType">
                    <option v-if="this.$route.params.currentModule==='fabric'
                    ||this.$route.params.currentModule==='smartLockers'
                    ||this.$route.params.currentModule==='electricalMonitoring'"
                    value="machine">
                      {{dictionary[(this.$route.params.currentDisplay==='machines')?"Máquina":"Centro de mando"][session.user.language]}}
                    </option>
                    <option v-if="this.$route.params.currentModule==='devices'
                    ||this.$route.params.currentModule==='fabric'
                    ||this.$route.params.currentModule==='storage'
                    ||this.$route.params.currentModule==='smartLockers'
                    ||this.$route.params.currentModule==='electricalMonitoring'"
                    value="emplacement">
                      {{dictionary['Emplazamiento'][session.user.language]}}
                    </option>
                  </select>
  
                  <span v-if="parentType==='machine'" class="col-12 m-0 p-0 tx-bold tx-14">
                    {{dictionary[(this.$route.params.currentDisplay==='machines')?"Máquinas":"Centros de mando"][session.user.language]+": "}}
                  </span>
                  <select v-if="parentType==='machine'"
                  class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex" style="color:black; line-height:"
                  v-model="targetEntityId"
                  @change="onTargetEntity">
                    <option v-for="machine in availableMachines" :key="machine"
                    :value="machine.id">
                      {{machine.label}}
                    </option>
                  </select>
  
                  <span v-if="parentType==='emplacement'" class="col-12 m-0 p-0 tx-bold tx-14">
                    {{dictionary['Emplazamiento'][session.user.language]+": "}}
                  </span>
                  <select v-if="parentType==='emplacement'"
                  class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex" style="color:black; line-height:"
                  v-model="targetEntityId"
                  @change="onTargetEntity">
                    <option v-for="emplacement in availableEmplacements" :key="emplacement"
                    :value="emplacement.id">
                      {{emplacement.label}}
                    </option>
                  </select>
  
                </div>
                <div class="col-lg-12 col-md-12 col-12 p-2 ps-1 pe-1">
  <!-- SUBMIT -->
                  <button type="button" id="btnSubmitTargetMachine" class="button-ok gradient-tablas w-100"
                  @click="linkMachineToEntity">
                    {{dictionary['Aceptar'][session.user.language]}}
                  </button>
                </div>
  
                <div class="col-lg-12 col-md-12 col-12 p-2 ps-1 pe-1">
  <!-- DISMISS -->
                  <button type="button" id="btnCancelTargetMachine" class="button-ok gradient-tablas w-100"
                  @click="this.$emit('dismissAlert')">
                    {{dictionary['Cancelar'][session.user.language]}}
                  </button>
                </div>
              </div>
  
              <div v-if="linkAction === 'link'" 
              class="col-lg-8 col-md-8 col-12 m-0 p-2 d-flex flex-column tx-left">
                <div class="col-12 d-flex flex-wrap justify-content-center bg-menu rounded-5 text-center m-0 p-3">
                  
                  <div class="blueprintCanvas w-100 m-0 p-0">
                    <img id="emplacementBlueprintForMachine" class="w-100 m-0 p-0 rounded-3"
                    @click="onSetNewCoordinates"
                    :src="displayBlueprint()">
                    <div id="linkMachineBlueprintMarker" style="color: red;">
                      <ion-icon name="locate-outline" class="tx-20"></ion-icon>
                    </div>
                  </div>
  <!-- LATITUD -->
                  <div class="col-lg-6 col-12 m-0 p-2" style="text-align: left;">
                    <label class="m-0 p-0 tx-13 tx-clomos-white">
                      {{dictionary['Latitud'][session.user.language]}} *
                    </label><br>
                    <input v-model="newGeoJSON.geometry.coordinates[0]" 
                    id="inputLinkDevciceLat" type="number" step=0.00001
                    class="form-control form-control-line" 
                    @change="onPositionChanged">
                  </div>
  
  <!-- LONGITUD -->
                  <div class="col-lg-6 col-12 m-0 p-2" style="text-align: left;">
                    <label class="m-0 p-0 tx-13 tx-clomos-white">
                      {{dictionary['Longitud'][session.user.language]}} *
                    </label><br>
                    <input v-model="newGeoJSON.geometry.coordinates[1]" 
                    id="inputLinkDevciceLng" type="number" step=0.00001
                    class="form-control form-control-line" 
                    @change="onPositionChanged">
                  </div>
                  
                </div>
              </div>
              
              <div v-else
              class="col-lg-8 col-md-8 col-12 m-0 p-2 d-flex flex-column tx-left">
                <!--<span class="col-12 m-0 p-0 tx-bold tx-clomos-midtone">
                  {{dictionary['Posición'][session.user.language]+": "}}
                </span>-->
  
                <div class="col-12 d-flex flex-wrap justify-content-center bg-menu rounded-5 text-center m-0 p-3">
                  <div id="machineMapContent" class="m-0 p-3 rounded-3" style="width: 100%; height: 275px; index: 0;">
                  </div>
  <!-- LATITUD -->
                  <div class="col-lg-6 col-12 m-0 p-2" style="text-align: left;">
                    <label class="m-0 p-0 tx-13 tx-clomos-white">
                      {{dictionary['Latitud'][session.user.language]}} *
                    </label><br>
                    <input v-model="newGeoJSON.geometry.coordinates[0]" 
                    id="inputLinkMachineLat" type="number" step=0.00001 
                    class="form-control form-control-line" 
                    @change="onPositionChanged">
                  </div>
  
  <!-- LONGITUD -->
                  <div class="col-lg-6 col-12 m-0 p-2" style="text-align: left;">
                    <label class="m-0 p-0 tx-13 tx-clomos-white">
                      {{dictionary['Longitud'][session.user.language]}} *
                    </label><br>
                    <input v-model="newGeoJSON.geometry.coordinates[1]" 
                    id="inputLinkMachineLng" type="number" step=0.00001 
                    class="form-control form-control-line" 
                    @change="onPositionChanged">
                  </div>
                </div>
              </div>
  
              <div class="row m-0 p-2">
              </div>
  
            </div>
          </div>
        </div>
  
      </div>
    </div>
  
  </template>
  
  <script>
  import $ from 'jquery';
  import Leaflet from 'leaflet';
  // Clomos
  import api from '@/services/api';
  
  export default {
    name: "TargetMachineModal",
    props: {
      session: {
        type: Object,
        requiered: true,
      },
      dictionary: {
        type: Object,
        requiered: true,
      },
      targetMachineId: {
        type: String,
        requiered: true,
      },
      linkAction: {
        type: String,
        requiered: true,
      },
      currentCompany: {
        type: Object,
        requiered: true,
      },
    },
    data() {
      return {
        targetMachineData: {},
        parentType: "emplacement",
        targetEntityId: "",
        targetEntity: {},
        availableMachines: [],
        availableEmplacements: [],
        newGeoJSON: { 
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0,0]
          }
        },
        machineMap: undefined,
        machineMarker: undefined
      }
    },
    computed: {
      bluePrintMarkerPosition() {
        let markerX = 0;
        let markerY = 0;
        let blueprintCanvas = document.getElementById('emplacementBlueprintForMachine');
        if (blueprintCanvas != null) {
          let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
          let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;
          
          let domX = this.newGeoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 25 ;
          let domY = this.newGeoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 25;
    
          markerX = Math.floor(domX / ratioX);
          markerY = Math.floor(domY / ratioY);
        }
        return "color: red; position: absolute; top: " + markerY +"px; left: " + markerX + "px;"
      }
    },
    emits: ["dismissAlert"],
    methods: {
      // DISPLAY
      displayMap() {
        if (this.machineMap != undefined) {
          this.machineMap.invalidateSize();
          this.machineMap.remove();
        }
        var machineMap = Leaflet.map("machineMapContent");
        Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang='+this.session.user.language,{maxZoom: 19}).addTo(machineMap);
        machineMap.on('click', this.onSetNewCoordinates)
        
        this.machineMap = machineMap;
        
        let machineMapListForGroup = []; 
        if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();
        var machineMarker = Leaflet.marker([this.newGeoJSON.geometry.coordinates[0],this.newGeoJSON.geometry.coordinates[1]]);
        machineMarker.addTo(machineMap);
        machineMapListForGroup.push(machineMarker);
        var machineMapGroup = Leaflet.featureGroup(machineMapListForGroup);
        this.machineMap.fitBounds(machineMapGroup.getBounds());
  
        this.machineMarker = machineMarker;
      },
      displayBlueprint() {
        let src = "";
        if (this.targetEntity != undefined
        && this.targetEntity.variables != undefined
        && this.targetEntity.variables.dynamic != undefined
        && this.targetEntity.variables.dynamic.urlPlano != undefined)
          src = this.targetEntity.variables.dynamic.urlPlano.value;
        if (src!=undefined && src!='') {
          try {
            return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
          }
          catch(e) { 
            console.warn(e);
          }
        }
        return require('/src/assets/img/placeholder_image.jpg');
      },
      onPositionChanged() {
        if (this.linkAction==="link")
          document.getElementById('linkMachineBlueprintMarker').style = this.bluePrintMarkerPosition;
        else {
          this.machineMap.removeLayer(this.machineMarker);
          this.machineMarker = Leaflet.marker([this.newGeoJSON.geometry.coordinates[0],this.newGeoJSON.geometry.coordinates[1]]).addTo(this.machineMap);
        }
      },
      onChangeParentType() {
        this.targetEntity = {};
        if (this.parentType === 'machine') {
          this.getMachineList();
        }
        if (this.parentType === 'emplacement') {
          this.getEmplacementList();
        }
      },
      // GET
      async getTargetMachineData(callback) {
        let response = await api.getMachineData(this.targetMachineId);
        this.targetMachineData = api.parseMachineFromResponse(response);
        if (callback && typeof callback === "function") callback(this.targetMachineData);
      },
      async getMachineList(callback) {
        let request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,  
          where: "",
        };
        let response = await api.getMachineListByCompany(request);
        let allAvailableMachines = api.parseMachinesFromResponse(response);
        this.availableMachines = [];
        for (let i in allAvailableMachines) {
          if (allAvailableMachines[i].id != this.targetMachineId) this.availableMachines.push(allAvailableMachines[i])
        }
        if (this.availableMachines[0] != undefined) {
          this.targetEntityId = this.availableMachines[0].id;
          this.targetEntity = this.availableMachines[0];
        }
        if (callback && typeof callback === "function") callback(this.availableMachines);
      },
      async getEmplacementList(callback) {
        let request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,  
          where: "",
        };
        let response = await api.getEmplacementList(request);
        this.availableEmplacements = api.parseEmplacementsFromResponse(response);
        if (this.availableEmplacements[0] != undefined) {
          this.targetEntityId = this.availableEmplacements[0].id;
          this.targetEntity = this.availableEmplacements[0];
        }if (callback && typeof callback === "function") callback(this.availableEmplacements);
      },
      // TARGET
      onTargetEntity(event) {
        this.targetEntityId = event.target.value;
        if (this.parentType==='machine') for (let i in this.availableMachines) {
          if (this.availableMachines[i].id === this.targetEntityId) 
          this.targetEntity = this.availableMachines[i];
          if (this.targetEntity != undefined
          && this.targetEntity.variables != undefined
          && this.targetEntity.variables.dynamic != undefined
          && this.targetEntity.variables.dynamic.urlPlano != undefined) {  
            this.displayBlueprint();
            this.newGeoJSON.properties = {srcId: this.targetEntity.variables.dynamic.urlPlano}
          }
        }
        else if (this.parentType==='emplacement') for (let i in this.availableEmplacements) {
          if (this.availableEmplacements[i].id === this.targetEntityId) 
          this.targetEntity = this.availableEmplacements[i];
          if (this.targetEntity != undefined
          && this.targetEntity.variables != undefined
          && this.targetEntity.variables.dynamic != undefined
          && this.targetEntity.variables.dynamic.urlPlano != undefined) {  
            this.displayBlueprint();
            this.newGeoJSON.properties = {srcId: this.targetEntity.variables.dynamic.urlPlano}
          }
        }
      },
      // SET 
      onSetNewCoordinates(event) {
        if (this.linkAction==="link") {
          let target = event.target;
          let totalOffsetLeft = target.offsetLeft;
          let totalOffsetTop = target.offsetTop;
          let totalScrollLeft = target.scrollLeft;
          let totalScrollTop = target.scrollTop;
          while (target.offsetParent != undefined) {
            target = target.offsetParent;
            totalOffsetLeft += target.offsetLeft;
            totalOffsetTop += target.offsetTop;
            totalScrollLeft += target.scrollLeft;
            totalScrollTop += target.scrollTop;
          }
          let ratioX = event.target.naturalWidth / event.target.offsetWidth;
          let ratioY = event.target.naturalHeight / event.target.offsetHeight;
  
          let domX = event.x + totalScrollLeft - totalOffsetLeft;
          let domY = event.y + totalScrollTop - totalOffsetTop;
  
          this.newGeoJSON.geometry.coordinates[0] = Math.floor(domX * ratioX);
          this.newGeoJSON.geometry.coordinates[1] = Math.floor(domY * ratioY);
  
          this.onPositionChanged();
        }
        else {
          this.newGeoJSON.geometry.coordinates = [ 
            Number(event.latlng.lat),
            Number(event.latlng.lng)
          ]
          this.machineMap.removeLayer(this.machineMarker);
          this.machineMarker = Leaflet.marker([this.newGeoJSON.geometry.coordinates[0],this.newGeoJSON.geometry.coordinates[1]]).addTo(this.machineMap);
        }
      },
      async linkMachineToEntity(callback) {
        let response = {};
        let request = {
          machineId : this.targetMachineId,
          parentId : this.targetEntityId,
          geoJSON : this.newGeoJSON
        }
        if (this.parentType === 'machine') {
          response = await api.setMachineParentAsMachine(request);
        }
        else if (this.parentType === 'emplacement') {
          response = await api.setMachineParentAsEmplacement(request);
        }
        if (response["status-code"]==="200") this.$emit('dismissAlert');
        if (callback && typeof callback === "function") callback(this.availableEmplacements);
      }
    },
    async created() {
      await this.getTargetMachineData();
      if (this.linkAction === "link") {
        await this.getEmplacementList();
        if (this.availableEmplacements[0]!=undefined)
          this.targetEntityId = this.availableEmplacements[0].id;
        setTimeout(()=>{this.onPositionChanged()}, 300);
      }
      else {
        this.targetEntityId = "";
        setTimeout(()=>{this.displayMap()}, 300);
      } 
    }
  }
  </script>
  
  <style>
  #textAlertModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }
  </style>