<template>
  <!-- SECTION Incidences-->
  <div id="incidencesSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

      <!-- HEADER incidences -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class="bg-menu border-1 rounded-5 box sombra">

          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
              <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
              <span> {{ dictionary["Incidencias"][session.user.language] }} </span>
              <span v-if="targetEmplacementData.label != undefined">: <br> {{ targetEmplacementData.label }} </span>
            </h6>
          </div>

          <div class="p-3 pt-0">
            <!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">
              <hr v-if="this.$route.params.currentDisplay === 'incidences'" class="m-0 p-0">

              <select v-if="this.$route.params.currentDisplay === 'incidences'" :value="currentCompany.nifCif"
                class="form-control mt-3 mb-3 h-100 text-nowrap bg-white"
                style="border: solid 2px white ; color: black;" @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0] != undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                  {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                </option>
              </select>

              <hr class="m-0 p-0 mb-3">
              <div class="mt-3 mb-3 d-flex flex-column">
                <!-- Label for the select element -->
                <label for="incidenceSelect" class="form-label text-start mb-2">{{ dictionary['Seleccione tipo incidencia'][session.user.language] }}</label>

                <!-- Select element -->
                <select v-if="incidenceList" v-model="selectedIncidence" id="incidenceSelect"
                  class="form-select form-control h-auto text-nowrap bg-white"
                  style="border: solid 2px white; color: black;" @change="onSelectedIncidence($event.target.value)"
                  @click="onSelectedIncidence($event.target.value)">
                  <option value="all">{{ dictionary['Todos'][session.user.language] }}</option>
                  <option v-for="incidence in incidenceList" :key="incidence.id" :value="incidence.id">
                    {{ incidence.label }}
                  </option>
                </select>
              </div>
            
              <div v-if="this.targetIncidenceLabel" class="row mb-3 ">
              <div class="col-6 p-1 justify-content-center d-flex">
                <button id="btnEditIncidence" type="button" class="button-ok gradient-tablas mt-3 rounded-3"
                  @click="toggleEditIncidenceModal()">
                  <span class="button-text text-white">
                    <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{ dictionary["Editar"][session.user.language]}}</span>
                  </span>
                </button>
              </div>

              <div class="col-6 p-1 justify-content-center d-flex">
                <button id="btnDeleteIncidence" type="button" class="button-ok gradient-tablas mt-3 rounded-3"
                  @click="toggleDeleteIncidenceModal">
                  <span class="button-text text-white">
                    <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{ dictionary["Eliminar"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            
          </div>
          <hr class="m-0 p-0 mb-3">

              <div class="mt-3 mb-3 d-flex flex-column">
                
                <label for="incidenceSelect" class="form-label text-start mb-2">{{ dictionary['Fecha inicio'][session.user.language] }}</label>
                <input type="datetime-local" v-if="incidenceList" class="form-select form-control  h-100 text-nowrap bg-white"
                  style="border: solid 2px white ; color: black;" v-model="historicSearchFilter.StartDate" @change="onSelectedStartDate($event.target.value)">
              </div>
              <hr class="m-0 p-0 mb-3">
              <div class="mt-3 mb-3 d-flex flex-column">
              
                <label for="incidenceSelect" class="form-label text-start mb-2">{{ dictionary['Fecha fin'][session.user.language] }}</label>
                <input type="datetime-local" v-if="incidenceList" class="form-select form-control  h-100 text-nowrap bg-white"
                  style="border: solid 2px white ; color: black;" v-model="historicSearchFilter.EndDate" @change="onSelectedEndDate($event.target.value)">
              </div>
              <div class="col-12 p-0 mb-3 justify-content-center d-flex">
                <button id="btnDisplayNewMachineModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3"
                  @click="searchSelectedFilter">
                  <span class="button-text text-white">
                    <ion-icon name="search-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{ dictionary["Buscar"][session.user.language] }}</span>
                  </span>
                </button>
              </div>
              <hr class="m-0 p-0 mb-3">
              <div class="row mb-3 ">
              <div class="col-6 p-1 justify-content-center d-flex">
                <button id="btnDisplayNewMachineModal" type="button" class="button-ok gradient-tablas rounded-3"
                  @click="toggleNewIncidenceModal">
                  <span class="button-text text-white">
                    <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{ dictionary["Crear tipo incidencia"][session.user.language]}}</span>
                  </span>
                </button>
              </div>           

              
              <div class="col-6 p-1 justify-content-center d-flex">
                <button id="btnDisplayNewIncidencesModal" type="button" class="button-ok gradient-tablas rounded-3"
                  @click="toggleOpenNewIncidence">
                  <span class="button-text text-white">
                    <ion-icon name="open-outline" class="text-white pt-0  tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{ dictionary["Abrir incidencia"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div v-if="this.incidenceList.length===0" class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
      </div>
      <!-- SECTION historic -->
      <HistoricIncidencesComponent v-if="this.$route.params.currentDisplay === 'incidences'&&this.incidenceList.length>0" :session="session"
        :dictionary="dictionary" :countries="countries" :visibility="visibility" :currentCompany="currentCompany"
        :requestedIncidenceId="targetIncidenceId" :requestedSearch="this.requestedSearch"
        :requestedIncidenceLabel=targetIncidenceLabel :incidenceTypes="incidenceList"
        :dataTableOptions="dataTableOptions">
      </HistoricIncidencesComponent>



    </div>
  </div>



  <!-- MODAL New Incidence -->
  <NewIncidenceModal v-if="display.newIncidenceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" v-on:toggleNewIncidenceModal="toggleNewIncidenceModal">
  </NewIncidenceModal>

  <!-- MODAL Edit Incidence -->
  <EditIncidenceModal v-if="display.editIncidenceModal" :session="session" :dictionary="dictionary"
    :countries="countries" :timeZones="timeZones" :currentCompany="currentCompany" :incidenceId="targetIncidenceId"
    v-on:toggleEditIncidenceModal="toggleEditIncidenceModal">
  </EditIncidenceModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteIncidenceModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :alertHeader="dictionary['Incidencia a eliminar'][session.user.language] + ': '"
    :alertTarget="targetIncidenceLabel"
    @acceptAlert="confirmDeleteIncidence"
    @dismissAlert="toggleDeleteIncidenceModal">
  </TextAlertModal>
  <!-- MODAL OpenNewIncidence -->
  <OpenNewIncidenceModal v-if="display.newHistoricModal" :session="session" :dictionary="dictionary"
    :countries="countries" :timeZones="timeZones" :currentCompany="currentCompany"
    :availableCompanies="availableCompanies" :incidenceTypes="incidenceList"
    v-on:toggleOpenNewIncidence="toggleOpenNewIncidence">
  </OpenNewIncidenceModal>
</template>

<script>

import HistoricIncidencesComponent from '@/components/incidences/HistoricIncidencesComponent';
import NewIncidenceModal from '@/components/incidences/NewIncidenceModal';
import EditIncidenceModal from '@/components/incidences/EditIncidenceModal';
import TextAlertModal from '@/components/other/TextAlertModal';
import OpenNewIncidenceModal from '@/components/incidences/OpenNewIncidenceModal'
// Clomos
import api from '@/services/api';

export default {
  name: "IncidencesComponent",
  components: { OpenNewIncidenceModal, HistoricIncidencesComponent, NewIncidenceModal, EditIncidenceModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    targetEmplacementId: {
      type: String,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: {
    targetIncidenceId: async function () {

  this.clearDates(); 
     
    },
    targetEmplacementId: async function () {
      if (this.$route.params.currentDisplay === 'incidences' || this.targetEmplacementId == '') {
        await this.getIncidencesByCompany();
      }
      
    }
  },
  data() {
    return {
   selectedIncidence: 'all',
      requestedSearch:{

      },
      targetEmplacementData: {},
      targetIncidenceId: "",
      targetIncidenceLabel: "",
      incidenceList: [],
      emplacementList: [],      
      display: {        
        newIncidenceModal: false,
        editIncidenceModal: false,
        deleteIncidenceModal: false,
      },
      historicSearchFilter: {},
      selectedCompany: "",      
    }
    
  },

  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onSelectedIncidence(value) {
      this.targetIncidenceId = value;
      this.targetIncidenceLabel = this.incidenceList.find(incidence => incidence.id === this.targetIncidenceId)?.label || "";
      const timeZone = this.session.user.dateTimeZone || 'UTC';   
      const today = this.$dayjs().tz(timeZone).format('YYYY-MM-DD HH:mm:ss');  
      const pastWeek = this.$dayjs().tz(timeZone).subtract(7, 'day').format('YYYY-MM-DD HH:mm:ss'); 
      this.historicSearchFilter.StartDate = pastWeek;
      this.historicSearchFilter.EndDate = today;
      this.$router.replace({ params: { targetEntity: this.targetIncidenceId } });
    },
    clearDates() {
      this.historicSearchFilter.StartDate = null;
      this.historicSearchFilter.EndDate = null;
    },
    onSelectedStartDate(value) {
      this.historicSearchFilter.StartDate = value
      if(this.historicSearchFilter.EndDate&&this.historicSearchFilter.EndDate<this.historicSearchFilter.StartDate) {
        window.alert("la fecha fin tiene que ser igual o mayor a la fecha inicio")
      }
    },
    onSelectedEndDate(value) {
      this.historicSearchFilter.EndDate = value
      if(this.historicSearchFilter.StartDate&&this.historicSearchFilter.EndDate<this.historicSearchFilter.StartDate) {
        window.alert("la fecha fin tiene que ser igual o mayor a la fecha inicio")
      }
    },
    resetSelectedIncidence() {
      this.selectedIncidence = ""
    },
    searchSelectedFilter() {
      if (this.historicSearchFilter.StartDate && this.historicSearchFilter.EndDate) {
        this.historicSearchFilter.action = "search";
        this.historicSearchFilter.incidenceId = this.targetIncidenceId;
        this.historicSearchFilter.nifCif=this.currentCompany.nifCif;
        this.requestedSearch = { ...this.historicSearchFilter }; 
        return this.requestedSearch;        
      }
    },
    async onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
      this.selectedCompany = value
      await this.getIncidencesByCompany(this.selectedCompany);      
      this.selectedIncidence='all'
    },   
 
    async toggleNewIncidenceModal() {
      if (this.display.newIncidenceModal) {
        if (this.$route.params.currentDisplay === 'incidences' || this.targetEmplacementId == '') {
          await this.getIncidencesByCompany();
        }      
      }
      this.display.newIncidenceModal = !this.display.newIncidenceModal;
      this.resetSelectedIncidence
    },
    async toggleEditIncidenceModal() {
      if (this.display.editIncidenceModal) {
        if (this.$route.params.currentDisplay === 'incidences' || this.targetEmplacementId == '') {
          await this.getIncidencesByCompany();
        }      
      }
      this.display.editIncidenceModal = !this.display.editIncidenceModal;
    },
    async toggleDeleteIncidenceModal() {
      if (this.display.deleteIncidenceModal) {
        if (this.$route.params.currentDisplay === 'incidences' || this.targetEmplacementId == '') {
          await this.getIncidencesByCompany();
        }      
        if (this.incidenceList[0] != undefined) {
          this.targetIncidenceId = this.incidenceList[0].id;
          this.targetIncidenceLabel = this.incidenceList[0].label;
        }
      }
      this.display.deleteIncidenceModal = !this.display.deleteIncidenceModal;
    }, async toggleOpenNewIncidence() {
      if (this.display.newHistoricModal) {
        await this.getIncidencesByCompany();
      }
      this.display.newHistoricModal = !this.display.newHistoricModal;
      this.resetSelectedIncidence
    },    
    // GET
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif ? this.currentCompany.nifCif : this.session.user.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    async getIncidencesByCompany(company) {
      let request = {
        nifCif: company ? company : this.selectedCompany ? this.selectedCompany : this.session.user.nifCif,
        limit: 0,
        offset: 0,

      };
      let response = await api.getIncidencesByCompany(request);
      this.incidenceList = api.parseIncidenceListFromResponse(response);
    },

    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
   
    // SET 
    onEditIncidence(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetIncidenceId = event.target.getAttribute('data-id');
      this.targetIncidenceLabel = this.incidenceList.find(incidence => incidence.id === this.targetIncidenceId)?.label || "";
      this.$router.replace({ params: { targetEntity: this.targetIncidenceId } })
      this.toggleEditIncidenceModal();
    },

    // DELETE
    async confirmDeleteIncidence() {
      try {
        let response = await api.deleteIncidence(this.targetIncidenceId);
        if (response["status-code"] === "200") {
          window.alert("La incidencia ha sido eliminado satisfactoriamente.");
        } else {
          throw new Error("Error al eliminar la incidencia.");
        }
      } catch (error) {
        window.alert(error.message);
      } finally {
        await this.toggleDeleteIncidenceModal();
      }
    }
    
  },
  async created() {
    await this.getEmplacementList();
    if (this.$route.params.currentDisplay === 'incidences') {
      await this.getIncidencesByCompany();
    }
    this.$emit('getAvailableCompanies'); 
  } 
}
</script>

<style>


</style>