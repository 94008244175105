<template>
<!-- SECTION Devices-->
  <div id="devicesSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
  
<!-- HEADER Devices -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class="bg-menu border-1 rounded-5 box sombra">
          
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
              <ion-icon name="hardware-chip" class="tx-18 me-2 align-bottom"></ion-icon>
              <span> {{dictionary["Dispositivos"][session.user.language] }} </span>
              <span v-if="targetMachineData.label != undefined"> 
                {{ ": "+targetMachineData.label }} 
              </span>
              <span v-else-if="this.targetEmplacementData.label != undefined"> 
                {{ ": "+targetEmplacementData.label }} 
              </span>
              <span v-else-if="currentCompany.name != undefined"> 
                {{ ": "+currentCompany.name }} 
              </span>
            </h6>
          </div>

          <div class="p-3 pt-0">
<!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">	
              <hr v-if="this.$route.params.currentDisplay==='devices'" class="m-0 p-0">

              <select v-if="this.$route.params.currentDisplay==='devices'"
              :value="currentCompany.nifCif"
              class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
              style="border: solid 2px white ; color: black;"
              @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif"
                :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif"
                :value="company.nifCif">
                  {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                </option>
              </select>

              <hr class="m-0 p-0 mb-3">
              
              <div class="row justify-content-around m-0 p-0">
                <div v-show="this.$route.params.currentDisplay==='devices'&&this.$route.params.currentModule!='electricalMonitoring'" class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('map')"
                  :style="(displayType==='map')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="map-outline" 
                    :style="(displayType==='map')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
                <div v-show="this.$route.params.currentDisplay!='devices'||this.$route.params.currentModule==='electricalMonitoring'" class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('blueprint')"
                  :style="(displayType==='blueprint')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="locate-outline" 
                    :style="(displayType==='blueprint')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
                <div class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('table')"
                  :style="(displayType==='table')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="list-outline" 
                    :style="(displayType==='table')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
                <div class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('cards')"
                  :style="(displayType==='cards')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="documents-outline" 
                    :style="(displayType==='cards')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
              </div>
              
              <hr class="m-0 p-0">

              <div class="col-12 p-0 justify-content-center d-flex">
                <button id="btnDisplayNewMachineModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                @click="toggleNewDeviceModal">
                  <span class="button-text text-white">
                    <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir dispositivo"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>
  
<!-- COMPONENT Devices -->
      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
          <!--<ion-icon v-if="session.user.bookmark==='devices'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
            <ion-icon name="hardware-chip-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>   
            <span> {{dictionary["Dispositivos"][session.user.language] }} </span>
            <span v-if="targetMachineData.label != undefined"> 
              {{ ": "+targetMachineData.label }} 
            </span>
            <span v-else-if="this.targetEmplacementData.label != undefined"> 
              {{ ": "+targetEmplacementData.label }} 
            </span>
            <span v-else-if="currentCompany.name != undefined"> 
              {{ ": "+currentCompany.name }} 
            </span>
          </div>
  
<!-- MAP Devices -->
          <div v-show="displayType==='map'"
          id="devicesMapContent" class="m-0 p-3" style="width: 100%; z-index: 0;"
          :style="(!display.showEverything)?'height: 15rem !important; hidden: scroll;':'height: 500px !important; hidden: scroll;'">
          </div>

<!-- BLUEPRINT Devices -->
          <div v-show="displayType==='blueprint'"
          id="devicesBlueprintContent" class="col-12 m-0 p-2 h-auto"
          :style="(!display.showEverything)?'height: 15rem !important; overflow: scroll; overflow-x: hidden;':''">
            <div class="blueprintCanvas col-9 m-0 p-0">
              <img id="emplacementBlueprintForDevices" class="w-100 m-0 p-2"
              :src="displayDevicesBlueprint()">
              <div v-for="device in deviceList" :key="device" :data-id="device.id"
              class="bg-caja rounded-3 p-2 deviceBlueprintMarker"
              :style="bluePrintMarkerPosition(device)">
                <div class="d-flex flex-column" style="min-width: 9rem;">
                  <span :data-id="device.id" class="btnTargetDevice c-pointer tx-bold tx-grey m-0 p-0">
                    {{device.label}}
                  </span>
                  <div class="m-0 p-0 d-flex flex-row justify-content-between">
                    <text class="w-100"> {{device.location}} </text>
                    <ion-icon name="create-outline" :data-id="device.id" class="btnEditDevice col-2 m-0 ps-1 p-0 btn tx-24 tx-primary float-end"></ion-icon>
                    <ion-icon name="trash-outline" :data-id="device.id" class="btnDeleteDevice col-2 m-0 p-0 btn tx-24 tx-danger float-end"></ion-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>

<!-- TABLE Devices -->
          <div v-show="displayType==='table'" class="m-0 p-3 h-auto"
          id="devicesTableContent"
          :style="(!display.showEverything)?'height: 15rem !important; overflow: scroll; overflow-x: hidden;':''">
            <table id="devicesDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in devicesColumns" :key="column.data"
                  :width="column.width" class="fw-medium text-nowrap p-2">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>

<!-- CARDS Devices -->
          <div v-show="displayType==='cards'&&deviceList[0]!=undefined"
          id="devicesCardsContent" class="col-12 m-0 p-2 h-auto"
          :style="(!display.showEverything)?'height: 15rem !important; overflow: scroll; overflow-x: hidden;':''">
            <div class="col-12 m-0 p-0 d-flex flex-row flex-wrap">
              <div v-for="device in deviceCardsByPages[deviceCardsPage]" :key="device"
              class="col-xl-2 col-md-4 col-12 m-0 p-2">
                <h6 class="tx-left tx-clomos-white text-center text-nowrap m-0 p-2 ps-1 pt-2 gradient-tablas rounded-top-3">
                  <span class="m-auto">{{(device.label.length>18)?(device.label.substring(0,18)+'...'):device.label}}</span>
                </h6>

                <div class="col-12 border-1 rounded-bottom-3 m-0 p-3">
                  <div class="col-12 m-0 p-0 d-flex space-between">
                    <ion-icon name="pulse-outline" :data-id="device.id" class="btnTargetDevice col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                    <ion-icon name="create-outline" :data-id="device.id" class="btnEditDevice col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                    <ion-icon name="trash-outline" :data-id="device.id" class="btnDeleteDevice col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-danger"></ion-icon>
                  </div>
                  <hr class="col-12 mt-2 mb-2">
                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 5rem;">
                    <span class="tx-13 tx-bold">{{dictionary['Tipo'][session.user.language]+": "}}</span>
                    <span class="tx-11 tx-start">{{device.type}}</span>
                    <span class="tx-13 tx-bold">{{dictionary['Identificador'][session.user.language]+": "}}</span>
                    <span class="tx-11 tx-start">{{device.id}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-row m-0 p-2">
                <div v-for="page,index in deviceCardsByPages" :key="page" 
                class="border rounded c-pointer m-0 me-2 tx-13" :class="(deviceCardsPage===index)?'border-dark':''" style="height:2.5rem; width:2.5rem; padding: inherit;"
                @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index+1  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-show="displayType==='cards'&&deviceList[0]==undefined" class="m-3 p-3 border rounded">
            <span class="tx-13"> 
              {{dictionary['No hay dispositivos disponibles'][session.user.language]}} 
            </span> 
          </div>

        </div>

      </div>
      
      <div v-if="this.$route.params.currentDisplay==='devices'"
      class="col-12 m-0 p-2 pt-3 pb-0 d-flex flex-row">
        <hr class="tx-clomos-dark m-auto w-100">
        <ion-icon :name="(display.showEverything)?'eye-off-outline':'eye-outline'"
        class="bg-caja tx-clomos-dark targets-icons m-auto p-2 pt-1 pb-1 tx-40 border-1" style="vertical-align: middle; min-width:2rem; min-height:2rem; border-radius:3rem;"
        @click="display.showEverything=!display.showEverything"></ion-icon>
        <hr class="tx-clomos-dark m-auto w-100">
      </div>
    </div>
  </div>

<!-- SECTION Devices -->
  <StatusComponent v-if="this.$route.params.currentDisplay==='devices' "
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :requestedDeviceId="targetDeviceId"
  :dataTableOptions="dataTableOptions">
  </StatusComponent>

<!-- MODAL New Device -->
  <NewDeviceModal
  v-if="display.newDeviceModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  v-on:toggleNewDeviceModal="toggleNewDeviceModal">
  </NewDeviceModal>

<!-- MODAL Edit Device -->
  <EditDeviceModal
  v-if="display.editDeviceModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :deviceId="targetDeviceId"
  v-on:toggleEditDeviceModal="toggleEditDeviceModal">
  </EditDeviceModal>

<!-- MODAL Link Text Alert -->
  <LinkDeviceModal
  v-if="display.linkDeviceModal"
  :session="session"
  :dictionary="dictionary"
  :availableCompanies="availableCompanies"
  :currentCompany="currentCompany"
  :targetDeviceId="targetDeviceId"
  :linkAction="linkAction"
  v-on:dismissAlert="toggleLinkDeviceModal">
  </LinkDeviceModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteDeviceModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Dispositivo a eliminar'][session.user.language] + ': '"
  :alertTarget="targetDeviceId"
  v-on:acceptAlert="onDeleteDevice"
  v-on:dismissAlert="toggleDeleteDeviceModal">
  </TextAlertModal>
</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import StatusComponent from '@/components/status/StatusComponent';
import NewDeviceModal from '@/components/devices/NewDeviceModal';
import EditDeviceModal from '@/components/devices/EditDeviceModal';
import LinkDeviceModal from '@/components/devices/LinkDeviceModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "DevicesComponent",
  components: { StatusComponent, NewDeviceModal, EditDeviceModal, LinkDeviceModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    targetEmplacementId: {
      type: String,
      requiered: true,
    },
    targetMachineId: {
      type: String,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
        await this.getDevicesDataByCompany();
      }
      else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
        await this.getEmplacementData();
        await this.getDevicesDataByEmplacement();
      }
      else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
        await this.getDevicesDataByMachine();
      }
      this.onChangeDisplayType(this.displayType)
      if (this.deviceList[0] != undefined && this.targetDeviceId === "")
        this.targetDeviceId = this.deviceList[0].id;
    },
    targetEmplacementId: async function() {
      if (this.targetEmplacementId != "") {
        await this.getEmplacementData();
        await this.getDevicesDataByEmplacement();
      }
      this.onChangeDisplayType(this.displayType)
      if (this.deviceList[0] != undefined && this.targetDeviceId === "")
        this.targetDeviceId = this.deviceList[0].id;
    },
    targetMachineId: async function() {
      if (this.targetMachineId != "") {
        await this.getDevicesDataByMachine();
      }
      this.onChangeDisplayType(this.displayType)
      if (this.deviceList[0] != undefined && this.targetDeviceId === "")
        this.targetDeviceId = this.deviceList[0].id;
    }
  },
  data() {
    return {
      devicesColumns: [
        {text:"Identificador", data: "deviceId", width: "25%", className:"tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
            +"<span data-id="+data+" class='btnTargetDevice c-pointer c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data+"</span>";
          }
        },
        {text:"Nombre", data: "deviceLabel", width: "15%", className:"tx-left align-middle"},
        {text:"Fecha de comunicación", data: "deviceCommunicationDateTime", width: "10%", className:"align-middle",
          render: (data) => {
            return (data!="")
            ?"<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
            :"<span style='display: none;'></span> <spam>-</spam>";          }
        },
        {text:"Tipo", data: "deviceType", width: "10%", className:"tx-left align-middle"},
        {text:"Versión", data: "deviceVersion", width: "5%", className:"tx-left align-middle"},
        {text:"Vincular", data: "deviceParent", width: "5%", className:"align-middle",
          render: (data) => {
            return (data.parentId=="")
              ?"<ion-icon name='log-in-outline' data-id="+data.deviceId+" class='btnLinkDevice btn tx-24 tx-primary'></ion-icon>"
              :(data.parentLabel=="")?data.parentId :data.parentLabel;
          }
        },
        {text:"Desvincular", data: "deviceParent", width: "5%", className:"align-middle",
          render: (data) => {
            return (data.parentId!="")
              ?"<ion-icon name='log-out-outline' data-id="+data.deviceId+" class='btnUnlinkDevice btn tx-24 tx-primary'></ion-icon>"
              :"<ion-icon name='log-out-outline' data-id="+data.deviceId+" class='tx-24 tx-gray-400'></ion-icon>"
          }
        },
        {text:"Configuración", data: "deviceId", width: "5%", className:"align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id="+data+" class='btnEditDevice btn tx-24 tx-primary'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "deviceId", width: "5%", className:"align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteDevice btn tx-24 tx-danger'></ion-icon>"
          }
        },
      ],
      targetEmplacementData: {},
      targetMachineData: {},
      targetDeviceId: this.$route.params.targetEntity,
      deviceList: [],
      emplacementList: [],
      devicesMap: undefined,
      displayType : "table",
      deviceCardsPage: 0,
      display: {
        showEverything: true,
        newDeviceModal: false,
        editDeviceModal: false,
        linkDeviceModal: false,
        deleteDeviceModal: false,
      },
      linkAction: "link",
      treeConfig: { nodeWidth: 250, nodeHeight: 120, levelHeight: 120 }
    }
  },
  computed: {
    viewpoint() {
      let viewpoint = {
        center: {lng: 0, lat:0},
        zoom: 5,
      }
      let l = undefined;
      let r = undefined;
      let t = undefined;
      let b = undefined;
      if (this.deviceList[1] != undefined) { // Multiple emplacements
        for (let i in this.deviceList) if (this.deviceList[i].geoJSON.geometry!=undefined && this.deviceList[i].geoJSON.geometry.type==="Point") {
          if (this.deviceList[i].geoJSON.geometry.coordinates[0] >= t || t === undefined) {
            t = Number(this.deviceList[i].geoJSON.geometry.coordinates[0]) 
          }
          if(this.deviceList[i].geoJSON.geometry.coordinates[0] <= b || b === undefined) {
            b = Number(this.deviceList[i].geoJSON.geometry.coordinates[0]);
          }
          if (this.deviceList[i].geoJSON.geometry.coordinates[1] >= r || r === undefined) {
            r = Number(this.deviceList[i].geoJSON.geometry.coordinates[1]) 
          }
          if(this.deviceList[i].geoJSON.geometry.coordinates[1] <= l || l=== undefined) {
            l = Number(this.deviceList[i].geoJSON.geometry.coordinates[1]);
          }
        }
        // CENTER
        viewpoint.center.lng = (r+l)/2;
        viewpoint.center.lat = (t+b)/2;
      }
      else if (this.deviceList[0] != undefined) { // Single emplacement
        viewpoint.center = {lng: parseFloat(this.deviceList[0].geoJSON.geometry.coordinates[0]), lat:parseFloat(this.deviceList[0].geoJSON.geometry.coordinates[1])};
      }
      return viewpoint;
    },
    deviceCardsByPages(){
      let deviceCardsByPages = []
      let i = 0; 
      while (i*12 < this.deviceList.length) {
        deviceCardsByPages.push(this.deviceList.slice(i*12,i*12+12));
        i++;
      }
      return deviceCardsByPages;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(()=>{
        if (type === "map") this.displayDevicesMap();
        if (type === "table") this.displayDevicesDataTable();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.deviceCardsPage = index;
      this.setButtonFunctions();
    },
    displayDevicesBlueprint() {
      setTimeout(()=>{
        $(".deviceBlueprintMarker").get().forEach(marker => {
          let markerId = marker.getAttribute("data-id");
          for (let i in this.deviceList) {
            if (this.deviceList[i].id === markerId) 
              marker.style = this.bluePrintMarkerPosition(this.deviceList[i]);
          }
        })
      },1000);
      let src = "";
      if (this.targetEmplacementData.variables != undefined
      && this.targetEmplacementData.variables.dynamic != undefined
      && this.targetEmplacementData.variables.dynamic.urlPlano != undefined)
        src = this.targetEmplacementData.variables.dynamic.urlPlano.value;
      if (src!=undefined && src!='') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    bluePrintMarkerPosition(device) {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('emplacementBlueprintForDevices');
      if (blueprintCanvas != null) {
        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;
        
        let domX = device.geoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 7 ;
        let domY = device.geoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 7;
  
        markerX = Math.floor(domX / ratioX);
        markerY = Math.floor(domY / ratioY);
      }
      return "position: absolute; top: " + markerY +"px; left: " + markerX + "px;"
    },
    displayDevicesMap() {
      if (this.devicesMap != undefined) {
        this.devicesMap.invalidateSize();
        this.devicesMap.remove();
      }
      var devicesMap = Leaflet.map("devicesMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang='+this.session.user.language,{maxZoom: 19}).addTo(devicesMap);
      
      
      var siteMarkersGroup = new MarkerClusterGroup();
      devicesMap.addLayer(siteMarkersGroup);

      let devicesMapListForGroup = []; 
      siteMarkersGroup.addLayers(this.deviceList.map(d => {
        const options = { title: d.label, clickable: true, draggable: false };
        let coordinates = [0,0];
        if (d.parent === "")
          coordinates = [d.geoJSON.geometry.coordinates[0],d.geoJSON.geometry.coordinates[1]]
        else for (let ii in this.emplacementList) if (d.parent === this.emplacementList[ii].id)
          coordinates = [this.emplacementList[ii].geoJSON.geometry.coordinates[0],this.emplacementList[ii].geoJSON.geometry.coordinates[1]]
        let emplacement = (d.emplacement!=undefined&&d.emplacement!="")?d.emplacement:this.dictionary["Sin localizar"][this.session.user.language];
        var marker = Leaflet.marker(coordinates, options);
        marker.on("click", this.setButtonFunctions);
        marker.bindPopup('<div class="d-flex flex-column" style="min-width: 9rem;">'
        +'<span data-id="' + d.id + '" class="btnTargetDevice c-pointer tx-bold tx-grey m-0 p-0">' + d.label + '</span>'
        +'<div class="m-0 p-0 d-flex flex-row justify-content-between">'
          +'<text class="w-100">' + emplacement + '</text>'
          +'<ion-icon name="create-outline" data-id="' + d.id + '" class="btnEditDevice col-2 m-0 mt-auto ps-1 p-0 btn tx-24 tx-primary float-end"></ion-icon>'
          +'<ion-icon name="trash-outline" data-id="' + d.id + '" class="btnDeleteDevice col-2 m-0 mt-auto p-0 btn tx-24 tx-danger float-end"></ion-icon>'
        +'</div></div>');
        devicesMapListForGroup.push(marker);
        return marker;
      }))
      if (devicesMapListForGroup[0] != undefined) {
        var devicesMapGroup = Leaflet.featureGroup(devicesMapListForGroup);
        devicesMap.fitBounds(devicesMapGroup.getBounds());
      } else {
        devicesMap.setView(new Leaflet.LatLng(40.426, -3.7034), 10);
      }

      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();

      this.devicesMap = devicesMap;
    },
    displayDevicesDataTable() {
      $("#devicesDataTable").DataTable().clear()
      for (let i in this.deviceList) {
        let parentLabel = "";
        if (this.targetMachineId != "") {
          parentLabel = this.targetMachineData.label;
        }
        else {
          for (let ii in  this.emplacementList) {
            if (this.emplacementList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.emplacementList[ii].label;
              break;
            }
          }
          if (parentLabel === "") for (let ii in  this.machineList) {
            if (this.machineList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.machineList[ii].label;
              break;
            }
          }
          if (parentLabel === "") for (let ii in  this.bundleList) {
            if (this.bundleList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.bundleList[ii].label;
              break;
            }
          }
          if (parentLabel === "") for (let ii in  this.deviceList) {
            if (this.deviceList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.deviceList[ii].label;
              break;
            }
          }
        }
        $("#devicesDataTable").DataTable().rows.add([{
          deviceId: this.deviceList[i].id, 
          deviceLabel: this.deviceList[i].label, 
          deviceCommunicationDateTime: (this.deviceList[i].communicationDateTime != undefined && this.deviceList[i].communicationDateTime != "") ? new Date(this.deviceList[i].communicationDateTime) : "", 
          deviceType: this.deviceList[i].type, 
          deviceParent: {parentId: this.deviceList[i].parent, parentLabel:parentLabel, deviceId:this.deviceList[i].id}, 
          deviceVersion: this.deviceList[i].version, 
        }]);
      }
      $("#devicesDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleNewDeviceModal() {
      if (this.display.newDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
          await this.getDevicesDataByCompany();
        }
        else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
          await this.getDevicesDataByMachine();
        } 
        this.onChangeDisplayType(this.displayType)
      }
      this.display.newDeviceModal = !this.display.newDeviceModal;
    },
    async toggleEditDeviceModal() {
      if (this.display.editDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
          await this.getDevicesDataByCompany();
        }
        else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
          await this.getDevicesDataByMachine();
        } 
        this.onChangeDisplayType(this.displayType)
      }
      this.display.editDeviceModal = !this.display.editDeviceModal;
    },
    async toggleLinkDeviceModal() {
      if (this.display.linkDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
          await this.getDevicesDataByCompany();
        }
        else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
          await this.getDevicesDataByMachine();
        } 
        this.onChangeDisplayType(this.displayType)
      }
      this.display.linkDeviceModal = !this.display.linkDeviceModal;
    },
    async toggleDeleteDeviceModal() {
      if (this.display.deleteDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
          await this.getDevicesDataByCompany();
        }
        else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
          await this.getDevicesDataByMachine();
        } 
        this.onChangeDisplayType(this.displayType)
        if (this.deviceList[0] != undefined) this.targetDeviceId = this.deviceList[0].id;
      }
      this.display.deleteDeviceModal = !this.display.deleteDeviceModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetDevice);
        });
        $(".btnEditDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onEditDevice);
        });
        $(".btnLinkDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkDevice);
        });
        $(".btnUnlinkDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkDevice);
        });
        $(".btnDeleteDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteDevice);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getEmplacementList(callback) {
      if ( 
        this.session.authorisation.availableModules[this.$route.params.currentModule]["emplacements"] === undefined
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    async getMachineList(callback) {
      if ( 
        this.session.authorisation.availableModules[this.$route.params.currentModule]["machines"] === undefined 
        && this.session.authorisation.availableModules[this.$route.params.currentModule]["commandCenters"] === undefined 
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.machineList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    async getBundleList(callback) {
      if ( 
        this.session.authorisation.availableModules[this.$route.params.currentModule]["bundles"] === undefined 
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.bundleList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.bundleList);
    },
    async getDevicesDataByCompany(callback) {
      if ( 
        this.session.authorisation.availableModules[this.$route.params.currentModule]["devices"] === undefined 
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      this.deviceList = api.parseDevicesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    async getDevicesDataByMachine(callback) {
      if ( 
        this.session.authorisation.availableModules[this.$route.params.currentModule]["devices"] === undefined 
      ) return;

      this.deviceList = [];
      let response = await api.getMachineData(this.targetMachineId);
      this.targetMachineData = api.parseMachineFromResponse(response);
      let childResponse;
      let child = this.targetMachineData;
      let parent;
      // LVL 0
      if (this.targetMachineData.children != "") {
        parent = {...child};
        let n = 0
        for (let childId in child.children) {
          if (child.children != "" && child.children[childId].claseDcl != "3") {
            n += 1;
            continue;
          }
          childResponse = await api.getDeviceData(childId);
          child = api.parseDeviceFromResponse(childResponse);
          if (child.dclClass === "3") {
            this.deviceList.push(child);
            // LVL 1
            if (child.children != "") {
              parent = {...child};
              let nn = 0;
              for (let childId in child.children) {
                if (child.children != "" && child.children[childId].claseDcl != "3") {
                  nn += 1;
                  continue;
                }
                childResponse = await api.getDeviceData(childId);
                child = api.parseDeviceFromResponse(childResponse);
                if (child.dclClass === "3") {
                  this.deviceList.push(child);
                  // LVL 2
                  if (child.children != "") {
                    parent = {...child};
                    let nnn = 0;
                    for (let childId in child.children) {
                      if (child.children != "" && child.children[childId].claseDcl != "3") {
                        nnn += 1;
                        continue;
                      }
                      childResponse = await api.getDeviceData(childId);
                      child = api.parseDeviceFromResponse(childResponse);
                      if (child.dclClass === "3") {
                        this.deviceList.push(child);
                        // LVL 3
                        if (child.children != "") {
                          parent = {...child};
                          let nnnn = 0;
                          for (let childId in child.children) {
                            if (child.children != "" && child.children[childId].claseDcl != "3") {
                              nnnn += 1;
                              continue;
                            }
                            childResponse = await api.getDeviceData(childId);
                            child = api.parseDeviceFromResponse(childResponse);
                            if (child.dclClass === "3") {
                              this.deviceList.push(child);
                              // LVL 4
                              if (child.children != "") {
                                parent = {...child};
                                let nnnnn = 0;
                                for (let childId in child.children) {
                                  if (child.children != "" && child.children[childId].claseDcl != "3") {
                                    nnnnn += 1;
                                    continue;
                                  }
                                  childResponse = await api.getDeviceData(childId);
                                  child = api.parseDeviceFromResponse(childResponse);
                                  if (child.dclClass === "3") {
                                    this.deviceList.push(child);
                                  }
                                  nnnnn += 1;
                                  if (Object.keys(child) === nnnnn) child = {...parent};
                                }
                              }
                            }
                            nnnn += 1;
                            if (Object.keys(child) === nnnn) child = {...parent};
                          }
                        }
                      }
                      nnn += 1;
                      if (Object.keys(child) === nnn) child = {...parent};
                    }
                  }
                }
                nn += 1;
                if (Object.keys(child) === nn) child = {...parent};
              }
            }
          }
          n += 1;
          if (Object.keys(child) === n) child = {...parent};
        }
      }
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    async getDevicesDataByEmplacement(callback) {
      if ( 
        this.session.authorisation.availableModules[this.$route.params.currentModule]["devices"] === undefined 
      ) return;

      let request = {
        emplacementId: this.targetEmplacementId,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getDeviceListByEmplacement(request);
      this.deviceList = api.parseDevicesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
    // TARGET 
    onTargetDevice(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetDeviceId}})
    },
    // SET 
    onEditDevice(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetDeviceId}})
      this.toggleEditDeviceModal();
    },
    // LINK 
    onLinkDevice(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetDeviceId}})
      this.linkAction = "link"
      this.toggleLinkDeviceModal();
    },
    // UNLINK 
    onUnlinkDevice(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetDeviceId}})
      this.linkAction = "unlink"
      this.toggleLinkDeviceModal();
    },
    // DELETE
    async onDeleteDevice(event) {
      if (!this.display.deleteDeviceModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetDeviceId);
        this.targetDeviceId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetDeviceId}})
      }
      else {
        let response = await api.deleteDevice(this.targetDeviceId);
        if (response["status-code"] === "200") {
          window.alert("El dispositivo ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el dispositivo.");
        }
      }
      await this.toggleDeleteDeviceModal();
    }
  },
  async created() {
    await this.getEmplacementList();
    await this.getMachineList();

    if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
      await this.getDevicesDataByCompany();
    }
    else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
      await this.getEmplacementData();
      await this.getDevicesDataByEmplacement();
    }
    else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
      await this.getDevicesDataByMachine();
    }
    this.$emit('getAvailableCompanies',this.onChangeDisplayType(this.displayType));
    if (this.deviceList[0] != undefined && this.targetDeviceId === "")
      this.targetDeviceId = this.deviceList[0].id;    
  },
  mounted() {
    $("#devicesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.devicesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover, .btnTargetDevice:hover, .btnLinkDevice:hover, .btnUnlinkDevice:hover, .btnEditDevice:hover, .btnDeleteDevice:hover {
  color: var(--main-dark) !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  padding: 0.5rem !important;
}

#organizationChart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.device-node {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.device-node-top {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px 5px 0 0;
}

.device-node-bot {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  color: black;
  border-radius: 0 0 5px 5px;
}
</style>