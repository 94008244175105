<template>
  <!-- MODAL New Incidence -->
  <div id="newIncidenceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">

      <!-- HEAD New Incidence -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Abrir incidencia'][session.user.language] }}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleOpenNewIncidence')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT New Incidence -->
      <div class="bg-caja col-12 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

            <form class="p-1 m-0 border rounded form-material">


              <div class="row">
                <!-- incidencia -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Tipo incidencia'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <select v-model="openNewIncidenceData.idIncidence" id="inputNewIncidence" @change="updateValues"
                      class="form-control form-control-line" style="color:black;">
                      <option v-for="item in this.incidenceList" :key="item.id" :value="item.id">
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- descripción incidencia -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Descripción'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <textarea class="form-control form-control-line" style="color:black;" v-model="descriptionText"
                      readonly></textarea>
                  </div>
                </div>
              </div>

              <div class="row" v-if="openNewIncidenceData.idIncidence">
                <!-- level -->

                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Nivel de gravedad'][session.user.language] }}
                  </label>
                  <div class="col-12 d-flex flex-wrap justify-content-around pt-2">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="muyBaja" value="0"
                        v-model="openNewIncidenceData.level" />
                      <label class="form-check-label tx-13" for="muyBaja">{{ dictionary['Muy baja'][session.user.language] }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="baja" value="1"
                        v-model="openNewIncidenceData.level" />
                      <label class="form-check-label tx-13" for="baja">{{ dictionary['Baja'][session.user.language]
                        }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="media" value="2"
                        v-model="openNewIncidenceData.level" />
                      <label class="form-check-label tx-13" for="media">{{
                     dictionary['media'][session.user.language][0].toUpperCase() +
                      dictionary['media'][session.user.language].slice(1) }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="alta" value="3"
                        v-model="openNewIncidenceData.level" />
                      <label class="form-check-label tx-13" for="alta">{{ dictionary['Alta'][session.user.language]
                        }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="critica" value="4"
                        v-model="openNewIncidenceData.level" />
                      <label class="form-check-label tx-13" for="critica">{{
                    dictionary['Critica'][session.user.language] }}</label>
                    </div>
                  </div>
                </div>
              </div>

                <div class="row">
                  <!-- DESCRIPCIÓN -->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{ dictionary['Observaciones'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <textarea v-model="openNewIncidenceData.description" id="inputNewProductDescription" type="text"
                        rows="1" class="form-control form-control-line" style="color:black;" />
                    </div>
                  </div>

                </div>


                <div class="row">

                  <!-- SUBMIT -->
                  <div class="form-group col-12 p-1">
                    <div class="col-12 mt-2 mb-2">
                      <button type="button" @click="onCreateOpenIncidence()" id="btnSubmitCreateNewProduct"
                        class="button-ok gradient-tablas rounded-3">
                        <span class="button-text text-white">
                          <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                          <span class="text-white mt-0">
                            {{ dictionary['Abrir incidencia'][session.user.language] }}</span>
                        </span>
                      </button>
                    </div>
                  </div>

                </div>

            </form>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "OpenNewIncidenceModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    incidenceTypes: {
      type: Object,
      requiered: true,
    }
  },
  data() {
    return {
      openNewIncidenceData: {
        idIncidence: "",
        observations: "",
        level: "",

      },
      incidenceList: this.incidenceTypes,
      descriptionText: ""
    }
  },
  emits: ["toggleOpenNewIncidence"],
  methods: {
    async onCreateOpenIncidence() {
      let valid = true;
      if (this.openNewIncidenceData.idIncidence === "" || this.openNewIncidenceData.idIncidence === undefined) {
        $("#inputNewIncidence").addClass("missing");
        valid = false;
      }
       if (this.openNewIncidenceData.description === "" || this.openNewIncidenceData.description === undefined) {
        $("#inputNewProductDescription").addClass("missing");
        valid = false;
      } 


      if (!valid) {
        setTimeout(() => {
          $(".missing").removeClass("missing");
        }, 3000);
        return;
      }


      this.openNewIncidenceData.observations = this.openNewIncidenceData.description

      try {

        let response = await api.postNewHistoricIncidence(this.openNewIncidenceData);
        if (response["status-code"] === "201") {
          console.log(response.idHistoricoIncidencia)
          this.$emit('toggleOpenNewIncidence');
          window.alert("La incidencia ha sido creado satisfactoriamente.");
        } else {
          window.alert("Error al crear la incidencias.");
        }
      } catch (error) {
        console.error("Error creating incidence:", error);
        window.alert("Error al crear la incidencia.");
      }
    },
    updateValues() {
      const selectedIncidence = this.incidenceList.find(item => item.id === this.openNewIncidenceData.idIncidence);
      if (selectedIncidence) {
        this.openNewIncidenceData.level = selectedIncidence.level;
        this.descriptionText = selectedIncidence.description
      }
    },


  },
  mounted() {

  }
}
</script>


<style>
#newIncidenceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>