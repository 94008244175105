<template>
<!-- MODAL Edit Slot -->
  <div id="editSlotModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="person" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Editar hueco'][session.user.language] + ": " + editSlotData.label }}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleEditSlotModal')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT Edit Slot -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="col-12 p-3 pe-0 pb-0" style="display: flex; flex-flow: wrap;">

<!-- FORM Slot -->
            <form class="p-0 pe-3 overflow-hidden">

              <div class="form-group border rounded p-3 col-12 d-flex flex-wrap">

<!-- NOMBRE -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-bold tx-clomos-midtone">
                    {{ dictionary['Nombre'][session.user.language] }}
                  </label>
                  <div class="col-md-12">
                    <input v-model="editSlotData.label" id="inputEditSlotName" type="text"
                      class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

<!-- TIPO -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-bold tx-clomos-midtone">
                    {{ dictionary['Tipo'][session.user.language] }}
                  </label>
                  <div class="col-md-12">
                    <input readonly v-model="editSlotData.type" id="inputEditSlotType" type="text"
                      class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

<!-- POSICIÓN -->
                <div class="form-group col-md-3 col-12 p-1">
                  <label class="col-12 tx-bold tx-clomos-midtone">
                    {{ dictionary['Fila'][session.user.language] }}
                  </label>
                  <div class="col-md-12">
                    <input v-model="editSlotData.geoJSON.geometry.coordinates[0]" id="inputEditSlotPositionRow" type="number"
                      min=0 class="form-control form-control-line" style="color:black;">
                  </div>
                </div>
                
                <div class="form-group col-md-3 col-12 p-1">
                  <label class="col-12 tx-bold tx-clomos-midtone">
                    {{ dictionary['Columna'][session.user.language] }}
                  </label>
                  <div class="col-md-12">
                    <input v-model="editSlotData.geoJSON.geometry.coordinates[1]" id="inputEditSlotPositionColumn" type="number"
                      min=0 class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

<!-- MÁQUINA -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-bold tx-clomos-midtone">
                    {{ dictionary['Locker'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <select readonly disabled v-model="editSlotData.parent" id="selectNewSlotParent"
                      class="form-control form-control-line" style="color:black;">
                      <option v-for="locker in lockerList" :key="locker.id" :value="locker.id">{{ locker.label }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="form-group col-md-6 col-12 p-1">
<!-- TOKEN -->
                  <label class="col-12 tx-bold tx-clomos-midtone">
                    {{ dictionary['Token'][session.user.language] }}
                  </label>
                  <div class="col-md-12">
                    <input v-model="editSlotData.token" id="inputEditSlotToken" type="text"
                    class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

<!-- FORCE OPEN-->
                <div class="form-group mt-4 col-md-6 col-12 p-1">
                  <button type="button" @click="forceSlotOpening" id="btnForceSlotOpenning"
                    class="button-open rounded-3 bs-gray col-md-6 col-12 ">
                    <span class="button-text text-white">
                      <ion-icon :name="editDataForm.apertura&&editDataForm.apertura.value === 'true' ? 'lock-closed-outline' : 'lock-open-outline'" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                      <span class="text-white mt-0">{{ (editDataForm.apertura&&editDataForm.apertura.value === "true") ? dictionary['Deshabilitar apertura remota'][session.user.language] : dictionary['Habilitar apertura remota'][session.user.language]}}</span>
                    </span>
                  </button>
                </div>
                <div class="col-12 mt-4">
<!-- SUBMIT SETTINGS-->
                  <button type="button" @click="setEditSlotData" id="btnSubmitSlotData"
                    class="button-ok gradient-tablas rounded-3">
                    <span class="button-text text-white">
                      <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                      <span class="text-white mt-0">{{ dictionary['Guardar cambios'][session.user.language] }}</span>
                    </span>
                  </button>
                </div>

              </div>

              <div class="border rounded mt-3 mb-3 p-3 col-12 d-flex flex-wrap">
                <label class="col-12 p-0 pt-3 tx-16 tx-bold text-nowrap tx-clomos-dark">
                  {{ dictionary['Información del hueco'][session.user.language] }}
                </label>

                <div v-if="editDataForm.cerrado!=undefined" class="col-lg-3 col-md-6 col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold text-nowrap tx-clomos-midtone">
                      {{ dictionary['Estado puerta'][session.user.language] }}
                    </label>
                    <div class="inputEditSlot form-control form-control-line border-0 tx-bold"
                      :class="editDataForm.cerrado.value">
                      {{ editDataForm.cerrado.value === "true" ? "Cerrado" : "Abierto" }}
                    </div>

                  </div>
                </div>

                <div v-if="editDataForm.apertura!=undefined" class="col-lg-3 col-md-6 col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold text-nowrap tx-nowrap tx-clomos-midtone">
                      {{ dictionary['Apertura remota'][session.user.language] }}
                    </label>
                    <div class="inputEditSlot form-control form-control-line border-0 tx-bold"
                      :class="editDataForm.apertura.value === 'true' ? 'true' : 'false'">
                      {{ editDataForm.apertura.value === "true" ? "Sí" : "No" }}
                    </div>
                  </div>
                </div>

                <div v-if="editDataForm.estado!=undefined"  class="col-lg-3 col-md-6 col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold text-nowrap tx-clomos-midtone">
                      {{ dictionary['Estado hueco'][session.user.language] }}
                    </label>
                    <div class="form-control form-control-line"
                    style="color:white;" :style="{
                    backgroundColor:
                      editDataForm.estado.value === '0' ? 'cornflowerblue' :
                      editDataForm.estado.value === '1' ? 'orange' :
                      editDataForm.estado.value === '2' ? 'red' :
                      editDataForm.estado.value === '3' ? 'yellow' : 'white'
                    }">
                      {{
                        editDataForm.estado.value === '0' ? 'Libre' :
                        editDataForm.estado.value === '1' ? 'Reservado' :
                        editDataForm.estado.value === '2' ? 'Ocupado' :
                        editDataForm.estado.value === '3' ? 'Espera' :
                        ''
                      }}
                    </div>
                  </div>
                </div>

                <div v-if="editDataForm.pedido!=undefined" class="col-lg-3 col-md-6 col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold text-nowrap tx-clomos-midtone">
                      {{ dictionary['ID pedido'][session.user.language] }}
                    </label>
                    <div :id="'inputEditSlot' + editDataForm.pedido.label" class="form-control form-control-line"
                      style="color:black;">
                      {{ editDataForm.pedido.value != "" ? editDataForm.pedido.value : "-" }}
                    </div>
                  </div>
                </div>

              </div>

              <div class="form-group border rounded mt-3 mb-3 p-3 col-12 d-flex flex-wrap">
                <label class="col-12 p-0 pt-3 tx-16 tx-bold tx-clomos-dark">
                  {{ dictionary['Configuración del hueco'][session.user.language] }}
                </label>
                <div v-if="editDataForm.alto!=undefined" class="col-lg-3 col-md-6 col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold text-nowrap tx-clomos-midtone">
                      {{ dictionary['Alto'][session.user.language] }}
                    </label>
                    <input v-model="editDataForm.alto.value" :id="'inputEditSlot' + editDataForm.alto.label" type="text"
                      :data-id="editDataForm.alto.variableId"
                      :data-type="editDataForm.alto.type"
                      :data-value="editDataForm.alto.value"
                      :disabled="(editDataForm.alto.isConfiguration === 'false') ? true : false"
                      class="inputEditSlot form-control form-control-line" style="color: black;"
                    >

                  </div>
                </div>
                <div v-if="editDataForm.ancho!=undefined" class="col-lg-3 col-md-6 col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold text-nowrap tx-clomos-midtone">
                      {{ dictionary['Ancho'][session.user.language] }}
                    </label>
                    <input v-model="editDataForm.ancho.value" :id="'inputEditSlot' + editDataForm.ancho.label" type="text"
                      :data-id="editDataForm.ancho.variableId" :data-type="editDataForm.ancho.type"
                      :data-value="editDataForm.ancho.value"
                      :disabled="(editDataForm.ancho.isConfiguration === 'false') ? true : false"
                      class="inputEditSlot form-control form-control-line" style="color: black;">

                  </div>
                </div>
                <div v-if="editDataForm.fondo!=undefined" class="col-lg-3 col-md-6 col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold text-nowrap tx-clomos-midtone">
                      {{ dictionary['Largo'][session.user.language] }}
                    </label>
                    <input v-model="editDataForm.fondo.value" :id="'inputEditSlot' + editDataForm.fondo.label" type="text"
                      :data-id="editDataForm.fondo.variableId" :data-type="editDataForm.fondo.type"
                      :data-value="editDataForm.fondo.value"
                      :disabled="(editDataForm.fondo.isConfiguration === 'false') ? true : false"
                      class="inputEditSlot form-control form-control-line" style="color: black;">

                  </div>
                </div>
                
<!-- TEMPERATURA DE CONSERVACIÓN -->
<div class="form-group d-flex flex-md-row flex-column col-12 p-0 ms-auto ">
                  <div v-if="editDataForm.tipo != undefined" class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{ dictionary['Tipo'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <select v-model="editDataForm.tipo.value"
                        class="inputEditSlot form-control form-control-line" style="color:black;"
                        :id="'inputEditSlot' + editDataForm.tipo.label"
                        :data-id="editDataForm.tipo.variableId"
                        :data-type="editDataForm.tipo.type"
                        :data-value="editDataForm.tipo.value"
                        :disabled="(editDataForm.tipo.isConfiguration === 'false') ? true : false">
                          <option v-if="editDataForm.tipo.value===''" key="" value="">{{ dictionary['Sin configurar'][session.user.language] }} </option>
                          <option key="frio" value="frio">{{ dictionary['Frío'][session.user.language] }} </option>
                          <option key="ambiente" value="ambiente">{{ dictionary['Ambiente'][session.user.language] }} </option>
                      </select>
                    </div>
                  </div>
                  
<!-- ACCESIBILIDAD -->
                  <div v-if="editDataForm.accesible != undefined" class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold text-nowrap tx-nowrap tx-clomos-midtone">
                      {{ dictionary['Accesibilidad'][session.user.language] }}
                    </label>
                    <div class="col-md-12">
                      <div class="inputEditSlot form-control form-control-line border-0 tx-bold c-pointer"
                        :id="'inputEditSlot' + editDataForm.accesible.label" type="text"
                        :data-id="editDataForm.accesible.variableId"
                        :data-type="editDataForm.accesible.type"
                        :data-value="editDataForm.accesible.value"
                        :disabled="(editDataForm.accesible.isConfiguration === 'false') ? true : false"
                        :class="editDataForm.accesible.value === 'true' ? 'true' : 'false'"
                        @click="editDataForm.accesible.value === 'true' ? editDataForm.accesible.value = 'false' : editDataForm.accesible.value = 'true'">
                        {{ editDataForm.accesible.value === "true" ? "Sí" : "No" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-4">
<!-- SUBMIT VARIABLES -->
                  <button type="button" @click="setSlotVariables" id="btnSubmitSlotVariables"
                    class="button-ok gradient-tablas rounded-3 ">
                    <span class="button-text text-white">
                      <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                      <span class="text-white mt-0">{{ dictionary['Guardar configuración'][session.user.language]}}</span>
                    </span>
                  </button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "EditSlotModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    slotId: {
      type: String,
      requiered: true,
    },
    targetLockerId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      editDataForm: {       
      },
      editSlotData : {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        type: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        geoJSON: { 
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0,0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {},
        }
      },
      slotConfigurationForm: {
        id: "",
        variableId: "",
        value: ""
      },
      lockerList: []
    }
  },
  emits: ["toggleEditSlotModal"],
  methods: {
    // SET
    async setEditSlotData() {
      let response = await api.setSlotData(this.editSlotData);
      if (response["status-code"] === "200") {
        window.alert("El hueco ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el hueco.");
      }
      this.getEditSlotData();
    },
    async setSlotVariables() {
      let variablesData = {
        slotId : this.editSlotData.id
      };
      $(".inputEditSlot").get().forEach(input => {
        let variableLabel = input.id.replace("inputEditSlot","");
        let ic = false;
        if (this.editSlotData.variables.analog[variableLabel] != undefined
        && this.editSlotData.variables.analog[variableLabel].isConfiguration==="true")
          ic = true;
        if (this.editSlotData.variables.digital[variableLabel] != undefined
        && this.editSlotData.variables.digital[variableLabel].isConfiguration==="true")
          ic = true;
        if (this.editSlotData.variables.dynamic[variableLabel] != undefined
        && this.editSlotData.variables.dynamic[variableLabel].isConfiguration==="true")
          ic = true;
        if (ic) {
          variablesData[variableLabel] = {
          value: (input.getAttribute("data-type")==="0")
            ?(input.getAttribute("data-value")==="true")
              ?1
              :0
            :input.value,
          id: input.getAttribute("data-id"),
          type: input.getAttribute("data-type")}
        }
      });      
      let response = await api.setSlotVariables(variablesData);
      if (response["status-code"] === "200") {
        window.alert("El hueco ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el hueco.");
      }
      this.getEditSlotData();
    },
    async setSlotConfiguration() {
      let response = await api.setBundleConfiguration(this.slotConfigurationForm);
      if (response["status-code"] === "200") {
        window.alert("El hueco ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el hueco.");
      }
      this.getEditSlotData();
    },
    async forceSlotOpening() {
      await api.forceSlotOpening(this.slotId);
      await this.getEditSlotData();
    },
    // GET 
    async getEditSlotData(callback) {
      let response = await api.getSlotData(this.slotId);
      this.editSlotData = api.parseBundleFromResponse(response);
      if (this.editSlotData&&response){
        this.editDataForm = {
          cerrado: this.editSlotData.variables.digital['cerrado']!=undefined?this.editSlotData.variables.digital['cerrado'] : undefined,
          apertura: this.editSlotData.variables.digital['solicitaAperturaRemota']!=undefined?this.editSlotData.variables.digital['solicitaAperturaRemota'] : undefined,
          accesible: this.editSlotData.variables.digital['accesible']!=undefined?this.editSlotData.variables.digital['accesible'] : undefined,
          alto: this.editSlotData.variables.analog['alto']!=undefined?this.editSlotData.variables.analog['alto'] : undefined,
          ancho: this.editSlotData.variables.analog['ancho']!=undefined?this.editSlotData.variables.analog['ancho'] : undefined,
          fondo: this.editSlotData.variables.analog['fondo']!=undefined?this.editSlotData.variables.analog['fondo'] : undefined,
          estado: this.editSlotData.variables.analog['estado']!=undefined?this.editSlotData.variables.analog['estado'] : undefined,
          tipo: this.editSlotData.variables.dynamic['tipo']!=undefined?this.editSlotData.variables.dynamic['tipo'] : undefined,
          pedido: this.editSlotData.variables.dynamic['idPedido']!=undefined?this.editSlotData.variables.dynamic['idPedido'] : undefined,
        }
      }
      if (this.editSlotData.variables.dynamic['configuracion']!=undefined)
        this.slotConfigurationForm = {
          id: this.editSlotData.id,
          variableId: this.editSlotData.variables.dynamic['configuracion'].variableId,
          value: this.editSlotData.variables.dynamic['configuracion'].value
        }
      else 
        this.slotConfigurationForm = {
          id: this.editSlotData.id,
          variableId: "",
          value: ""
        }
      if (callback && typeof callback === "function") callback(this.editSlotData);
    },
    async getLockerListByCompany() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getLockerListByCompany(request);
      for (let i in response.lockers) [
        this.lockerList[i] = {
          id: response.lockers[i].idDcl,
          label: response.lockers[i].etiqueta,
        }
      ]
    }
  },
  async created() {
    await this.getEditSlotData();
    await this.getLockerListByCompany();
  }
}
</script>

<style>
  #editSlotModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }

  .button-open {
    border: 0;
    position: relative;
    border: 1px solid var(--main-white);
    color: #fff;
    padding: 0;
    box-shadow: 0 0 0 1px 0 0 0 1px rgba(235, 19, 19, 0.14);
    justify-content: center;
    align-items: center;
    transition-property: border-color,  color, transform, box-shadow;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    font-size: 15px;
    line-height: 15px;
    height: 2.5rem;
    width: 100%;
    max-width: 250px;
    border-radius: 0.3rem;
    transition: 0.25s;
    background-color: darkslategray;
  }

  .button-open:hover {
    border: 1px solid var(--main-light);
    background-color: #2c3e50; 
    color: #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3); 
    transition: background-color 0.25s, color 0.25s, box-shadow 0.25s;
  }
</style>